<template>
  <div>
    <kvkk-inline
      v-if="customer.id"
      :customer-id="customer.id"
      :refresh-data="getCustomer"
    />
    <kvkk-modal
      v-if="customer.id"
      :customer-id="customer.id"
      :modal-show="Number(customer.kvkk) !== 1"
      :refresh-data="getCustomer"
    />

  </div>
</template>

<script>
import KvkkInline from '@/layouts/components/common/KvkkInline.vue'
import KvkkModal from '@/layouts/components/common/KvkkModal.vue'

export default {
  components: {
    KvkkInline,
    KvkkModal,
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
  },
  created() {
    this.getCustomer()
  },
  methods: {
    getCustomer() {
      this.$store.dispatch('customers/customerView', 233436)
    },
  },
}
</script>
